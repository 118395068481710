import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
// import mondaySdk from "monday-sdk-js";
//Explore more Monday React Components here: https://style.monday.com/
import { Button, TextField, Flex } from "monday-ui-react-core";
import "monday-ui-react-core/tokens";
import queryString from 'query-string';
import DOMPurify from "dompurify";

// Usage of mondaySDK example, for more information visit here: https://developer.monday.com/apps/docs/introduction-to-the-sdk/
// const monday = mondaySdk();

const App = () => {
  const [context, setContext] = useState();
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [token, setToken] = useState('');

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const token = parsed.token;
    setToken(token);

    // // Notice this method notifies the monday platform that user gains a first value in an app.
    // // Read more about it here: https://developer.monday.com/apps/docs/mondayexecute#value-created-for-user/
    // monday.execute("valueCreatedForUser");

    // // TODO: set up event listeners, Here`s an example, read more here: https://developer.monday.com/apps/docs/mondaylisten/
    // monday.listen("context", (res) => {
    //   setContext(res.data);
    // });
  }, []);

  const handleChange = (event) => {
    const newUrl = DOMPurify.sanitize(event);
    setUrl(newUrl);
    // Simple validation for URL
    setIsValid(/^https:\/\/[^\s$.?#].[^\s]*$/.test(newUrl));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) {
      const redirectTo = `/auth/woocommerce?storeUrl=${encodeURIComponent(url)}&token=${encodeURIComponent(token)}`;
      window.location.href = redirectTo;
    } else {
      alert('Please enter a valid https URL.');
    }
  };

  // //Some example what you can do with context, read more here: https://developer.monday.com/apps/docs/mondayget#requesting-context-and-settings-data
  // const attentionBoxText = `Hello, your user_id is: ${
  //   context ? context.user.id : "still loading"
  // }.
  // Let's start building your amazing app, which will change the world!`;
  
  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <div>
          <h1>Connect to WooCommerce</h1>
          <Flex direction={Flex.directions.COLUMN} gap={Flex.gaps.MEDIUM} align={Flex.align.START}>
            <TextField title="Store Url" size={TextField.sizes.MEDIUM} placeholder="https://example.com" onChange={handleChange} value={url}/>
            <Button type={Button.types.SUBMIT}>Submit</Button>
          </Flex>
        </div>
      </form>   
    </div>
  );
};

export default App;
